<template>
  <div :class="type" style="display: flex; align-items: flex-end">
    <img
      :src="require(`@/assets/screen/earlyWarning/img_task_${type}.png`)"
      :alt="`img_task_${type}`"
    />
    <div
      style="
        display: flex;
        margin-bottom: 30px;
        margin-left: 12px;
        margin-right: 12px;
      "
    >
      <div>{{ typeMap[type].lable }}：</div>
      <div :style="`color:${typeMap[type].color}`">{{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  //组件注册
  components: {},
  //组件传值
  props: {
    type: {
      type: String,
      default: 'yes',
    },
    value: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      typeMap: {
        yes: {
          lable: '已完成',
          color: '#05FFFC',
        },
        no: {
          lable: '未完成',
          color: '#FFCC1B',
        },
      },
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.yes {
  flex-direction: row-reverse;
}
</style>
